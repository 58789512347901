var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "", persistent: "" },
      scopedSlots: _vm._u(
        [
          _vm.isCreateReadmeFile
            ? {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g({ attrs: { color: "secondary", text: "" } }, on),
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { small: "" } },
                          [_vm._v("add")]
                        ),
                        _vm._v(" add readme file ")
                      ],
                      1
                    )
                  ]
                }
              }
            : {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: "primary",
                            text: "",
                            block: _vm.isBlock
                          }
                        },
                        on
                      ),
                      [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex align-center justify-start",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-1", attrs: { small: "" } },
                              [_vm._v("edit")]
                            ),
                            _vm._v(" edit ")
                          ],
                          1
                        )
                      ]
                    )
                  ]
                }
              }
        ],
        null,
        true
      ),
      model: {
        value: _vm.fileEditorDialog,
        callback: function($$v) {
          _vm.fileEditorDialog = $$v
        },
        expression: "fileEditorDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-space-between align-center title primary--text",
                staticStyle: { width: "100%" }
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center text-uppercase" },
                  [
                    _c("v-icon", { staticClass: "mr-1" }, [_vm._v("edit")]),
                    _vm._v(" file edit ")
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { text: "", disabled: _vm.loading },
                        on: { click: _vm.discardChanges }
                      },
                      [_vm._v(" discard ")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "ml-1",
                        attrs: {
                          loading: _vm.loading,
                          color: "secondary",
                          text: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateFileContent()
                          }
                        }
                      },
                      [_vm._v("save")]
                    )
                  ],
                  1
                )
              ]
            )
          ]),
          _c("v-divider"),
          _vm._v(" "),
          _c("v-divider", { staticClass: "mb-1" }),
          _c("v-card-text", [
            !_vm.isCreateReadmeFile
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-center justify-space-between mt-5 mb-2"
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { small: "" } },
                          [_vm._v("mdi-file-outline")]
                        ),
                        _c(
                          "span",
                          {
                            staticClass:
                              "subtitle-1 primary--text font-weight-medium"
                          },
                          [
                            _vm._v(
                              _vm._s(
                                [
                                  _vm.fileData.local_path,
                                  _vm.fileData.short_id
                                ].join("/")
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-space-between align-center wrap-flex",
                    staticStyle: {
                      "background-color": "rgba(23, 168, 193, 0.1)",
                      width: "100%"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "mb-0 mt-0 ml-2 font-weight-bold caption",
                        staticStyle: { color: "rgba(0, 0, 0, 0.54)" }
                      },
                      [_vm._v("File Editor")]
                    )
                  ]
                ),
                _c("MonacoEditor", {
                  staticClass: "monaco-editor mb-3",
                  style: { height: _vm.queryLines },
                  attrs: {
                    language: _vm.language,
                    options: {
                      minimap: { enabled: false },
                      fontSize: 14,
                      renderLineHighlight: false,
                      automaticLayout: true,
                      autoIndent: true,
                      lineNumbersMinChars: 5,
                      scrollbar: { vertical: "hidden" },
                      scrollBeyondLastLine: false,
                      smoothScrolling: true
                    }
                  },
                  model: {
                    value: _vm.fileContent,
                    callback: function($$v) {
                      _vm.fileContent = $$v
                    },
                    expression: "fileContent"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "v-dialog",
                  {
                    attrs: { persistent: "", "max-width": "500" },
                    model: {
                      value: _vm.changeWarningDialog,
                      callback: function($$v) {
                        _vm.changeWarningDialog = $$v
                      },
                      expression: "changeWarningDialog"
                    }
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c("v-card-title", [
                          _c("span", { staticClass: "title primary--text" }, [
                            _vm._v("Discard changes")
                          ])
                        ]),
                        _c("v-divider", { staticClass: "mb-1" }),
                        _c("v-card-text", [
                          _c("p", [_vm._v("You have unsaved changes.")]),
                          _c("p", [
                            _vm._v(
                              "Are you sure you want to discard your changes?"
                            )
                          ])
                        ]),
                        _c(
                          "v-card-actions",
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "error", text: "" },
                                on: {
                                  click: function($event) {
                                    _vm.fileEditorDialog = false
                                  }
                                }
                              },
                              [_vm._v(" discard ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", text: "" },
                                on: {
                                  click: function($event) {
                                    _vm.changeWarningDialog = false
                                  }
                                }
                              },
                              [_vm._v(" review changes ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }