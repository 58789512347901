<template>
    <v-dialog fullscreen persistent v-model="fileEditorDialog">
        <template v-if="isCreateReadmeFile" v-slot:activator="{ on }">
            <v-btn color="secondary" v-on="on" text>
                <v-icon class="mr-1" small>add</v-icon>
                add readme file
            </v-btn>
        </template>
        <template v-else v-slot:activator="{ on }">
            <v-btn class="white--text" color="primary" v-on="on" text :block="isBlock">
                <div style="width:100%" class="d-flex align-center justify-start">
                    <v-icon class="mr-1" small>edit</v-icon>
                    edit
                </div>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <div style="width:100%" class="d-flex justify-space-between align-center title primary--text">
                    <div class="d-flex align-center text-uppercase">
                        <v-icon class="mr-1">edit</v-icon>
                        file edit
                    </div>
                    <div class="d-flex align-center">
                        <v-btn text :disabled="loading" @click="discardChanges">
                            discard
                        </v-btn>
                        <v-btn class="ml-1" :loading="loading" color="secondary" text @click="updateFileContent()">save</v-btn>
                    </div>
                </div>
            </v-card-title>
            <v-divider></v-divider> <v-divider class="mb-1"></v-divider>
            <v-card-text>
                <div v-if="!isCreateReadmeFile" class="d-flex align-center justify-space-between mt-5 mb-2">
                    <div class="d-flex align-center">
                        <v-icon small class="mr-1">mdi-file-outline</v-icon>
                        <span class="subtitle-1 primary--text font-weight-medium">{{ [fileData.local_path, fileData.short_id].join('/') }}</span>
                    </div>
                </div>
                <div>
                    <div class="d-flex justify-space-between align-center wrap-flex" style="background-color: rgba(23, 168, 193, 0.1); width:100%">
                        <span class="mb-0 mt-0 ml-2 font-weight-bold caption" style="color:rgba(0, 0, 0, 0.54)">File Editor</span>
                    </div>
                    <MonacoEditor
                        class="monaco-editor mb-3"
                        :style="{ height: queryLines }"
                        :language="language"
                        v-model="fileContent"
                        :options="{
                            minimap: { enabled: false },
                            fontSize: 14,
                            renderLineHighlight: false,
                            automaticLayout: true,
                            autoIndent: true,
                            lineNumbersMinChars: 5,
                            scrollbar: { vertical: 'hidden' },
                            scrollBeyondLastLine: false,
                            smoothScrolling: true
                        }"
                    />
                </div>
                <div>
                    <v-dialog persistent v-model="changeWarningDialog" max-width="500">
                        <v-card>
                            <v-card-title>
                                <span class="title primary--text">Discard changes</span>
                            </v-card-title>
                            <v-divider class="mb-1"></v-divider>

                            <v-card-text>
                                <p>You have unsaved changes.</p>
                                <p>Are you sure you want to discard your changes?</p>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="error" text @click="fileEditorDialog = false">
                                    discard
                                </v-btn>
                                <v-btn color="primary" text @click="changeWarningDialog = false">
                                    review changes
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
const MonacoEditor = () => import('vue-monaco')

export default {
    name: 'FileEditor',
    props: {
        fileData: Object,
        endpoint: String,
        language: String,
        isCreateReadmeFile: { default: false, type: Boolean },
        newReadmeFileContent: String,
        isBlock: { default: false, type: Boolean }
    },
    components: {
        MonacoEditor
    },
    data() {
        return {
            fileContent: '',
            fileEditorDialog: false,
            loading: false,
            originalFileContent: '',
            changeWarningDialog: false
        }
    },
    methods: {
        discardChanges() {
            if (this.$data.originalFileContent === this.$data.fileContent) {
                this.$data.fileEditorDialog = false
            } else {
                this.$data.changeWarningDialog = true
            }
        },
        warnUnload(event) {
            // Cancel the event as stated by the standard.
            event.preventDefault()
            // Older browsers supported custom message
            event.returnValue = ''
        },
        readFileContent() {
            this.$axios
                .get(`/files/${this.$props.fileData.fid}/download`, {
                    timeout: 10000,
                    responseType: 'blob'
                })
                .then(response => {
                    var reader = new FileReader()
                    reader.addEventListener('loadend', e => {
                        const text = e.srcElement.result
                        this.$data.originalFileContent = text
                        this.$data.fileContent = text
                    })
                    reader.readAsText(response.data)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        updateFileContent() {
            this.$data.loading = true
            var blob = new Blob([this.$data.fileContent])
            var formData = new FormData()
            if (this.isCreateReadmeFile === true) {
                formData.append('file', blob, 'README.md')
                formData.append('type', 'text/markdown')
                formData.append('name', 'README.md')
            } else {
                formData.append('file', blob, this.$props.fileData.short_id)
                formData.append('type', this.$props.fileData.type)
                formData.append('name', this.$props.fileData.short_id)
            }

            this.$axios
                .post(this.$props.endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    if (this.isCreateReadmeFile === true) {
                        this.$emit('readmeFileCreated')
                    } else {
                        this.$emit('readmeFileUpdated')
                    }
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { snid: this.$route.params.snid, setFetchingStatus: true })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to the edit the file.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
                .finally(() => {
                    this.$data.loading = false
                    this.$data.fileEditorDialog = false
                })
        }
    },
    mounted() {
        if (this.isCreateReadmeFile === false) {
            this.readFileContent()
        } else {
            this.$data.fileContent = this.$props.newReadmeFileContent
            this.$data.originalFileContent = this.$props.newReadmeFileContent
        }
    },
    computed: {
        queryLines() {
            if (this.$data.fileContent.split('\n').length === 1) {
                return '32px'
            } else {
                return this.$data.fileContent.split('\n').length * 22 + 'px'
            }
        }
    },
    watch: {
        fileEditorDialog: function(nextVal) {
            if (nextVal === true && this.isCreateReadmeFile === false) {
                this.readFileContent()
            } else if (nextVal === false && this.fileContent !== this.originalFileContent) {
                window.removeEventListener('beforeunload', this.warnUnload)
            }
        },
        fileContent: {
            handler: function(to, from) {
                if (to !== this.originalFileContent) {
                    window.addEventListener('beforeunload', this.warnUnload)
                } else {
                    window.removeEventListener('beforeunload', this.warnUnload)
                }
            },
            immediate: true
        }
    }
}
</script>
